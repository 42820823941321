
import { defineComponent, ref, toRef } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { hideModal } from "@/core/helpers/dom"
import * as Yup from "yup"

import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import type { ElForm } from "element-plus"

import { Product } from "@/store/modules/ProductModule"
import { BoxType } from "@/store/modules/BoxInfoModule"

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "new-box-item-info-modal",
  components: {},
  props: [
    'add_box_item_infos'
  ],
  setup(props) {
    const formRef = ref<FormInstance>()
    const store = useStore()
    const newBoxItemInfoModalRef = ref<null | HTMLElement>(null)

    const onSubmit = (formEl: FormInstance | undefined) => {
      console.log('onSubmit')

      let selected_items = tableData.value.filter((i) => {
        return (i as any).selected
      })

      console.log(selected_items)

      props.add_box_item_infos(selected_items)
      tableData.value = []
      hideModal(newBoxItemInfoModalRef.value)
    }

    const onCancel = () => {
      hideModal(newBoxItemInfoModalRef.value);
    }

    const products = ref(Array<Product>())

    // store
    //   .dispatch(Actions.GET_ALL_PRODUCTS)
    //   .then(() => {
    //     products.value = store.getters.currentAllProducts
    //   })
    //   .catch(() => {
    //     // const [error] = Object.keys(store.getters.getErrors);
    //     // Swal.fire({
    //     //   text: store.getters.getErrors[error],
    //     //   icon: "error",
    //     //   buttonsStyling: false,
    //     //   confirmButtonText: "Try again!",
    //     //   customClass: {
    //     //     confirmButton: "btn fw-bold btn-light-danger",
    //     //   },
    //     // });
    //   })

    const formData = ref({
      product_brand: "",
      ip: "",
      is_presale: "",
      name: "",
      min_price: null,
      max_price: null
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    })

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onReset = () => {
      formData.value.product_brand = ''
      formData.value.ip = ''
      formData.value.is_presale = ''
      formData.value.name = ''
      formData.value.min_price = null
      formData.value.max_price = null
    }

    const tableData = ref([])

    const query = () => {
      // 定义查询参数的接口
      interface QueryParams {
        product_brand: string;
        ip: string;
        is_presale: string;
        name: string;
        currentPage: number;
        pageSize: number;
        min_price?: number | null;
        max_price?: number | null;
      }

      // 创建一个新对象，过滤掉 null 值
      const queryParams: QueryParams = {
        product_brand: formData.value.product_brand,
        ip: formData.value.ip,
        is_presale: formData.value.is_presale,
        name: formData.value.name,
        currentPage: page.value.currentPage,
        pageSize: page.value.pageSize,
      }
      
      // 只有当价格不为 null 时才添加到查询参数中
      if (formData.value.min_price !== null) {
        queryParams.min_price = formData.value.min_price
      }
      if (formData.value.max_price !== null) {
        queryParams.max_price = formData.value.max_price
      }

      store
        .dispatch(Actions.GET_PRODUCTS, queryParams)
        .then(() => {
          tableData.value = store.getters.currentProducts
          page.value.totalResult = store.getters.currentProductCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const product_brand_options = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_PRODUCT_BRAND_OPTIONS)
      .then(() => {
        product_brand_options.value = [{key: '', value: ''}]
        product_brand_options.value = product_brand_options.value.concat(store.getters.currentProductBrandOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const product_ip_options = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_PRODUCT_IP_OPTIONS)
      .then(() => {
        product_ip_options.value = [{key: '', value: ''}]
        product_ip_options.value = product_ip_options.value.concat(store.getters.currentProductIpOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const presale_options = ref(Array<BoxType>())
    presale_options.value = [{key: '', value: ''}, {key: '是', value: 'true'}, {key: '否', value: 'false'}]

    const submitEvent = () => {
      query()
    }

    return {
      newBoxItemInfoModalRef,
      onSubmit,
      formRef,
      products,
      onCancel,
      formData,
      onPageChange,
      tableData,
      page,
      product_brand_options,
      product_ip_options,
      presale_options,
      submitEvent,
      onReset
    };
  },
});
